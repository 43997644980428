import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import '../../MainStyle.css';
import { API_ACCESS_URL,AVATARS_ARR } from '../modals/Constants';
import { getStorage } from '../modals/Storage';

function UpdateAccount(){
    const navigate = useNavigate();
    const [avatarURL, setAvatarURL] = useState("");
    const [pageConst, setConstants] = useState({
        pageTitle: "Update Account",
        isLoadingShow: false,
    });

    const topBarClickAction = (data) =>{
        if(data=="multiBtn1"){
          navigate('/withdraw', { replace: false });
        }
    }

    const setAvatar = () =>{
        function getRandomNumber(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
        }
    
        let randomNumber = getRandomNumber(0,AVATARS_ARR.length-1);
        setAvatarURL(AVATARS_ARR[randomNumber].avatarURL);
    }
  
    useEffect(() => {
        setAvatar();
    }, [avatarURL]);

    return (
      <div className="v-center bg-black ovf-hidden">
        <div className='h-100vh res-wth bg-black'>
          <div className={`pr-v ovf-scrl-y hide-sb zoomAnimView`}>
           <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>

           <div className="col-view pd-20 mg-t-45 mg-b-15">

              <Link className="row-view cl-black pd-15 sb-view txt-deco-n br-10 bg-grey-black" to={"/changeavatar"}>
                <div className="v-center">
                  <div className="h-w-28 ovf-hidden br-50">
                    <img src={AVATARS_ARR[Number(getStorage("avatarId"))-1]['avatarURL']} className="h-w-28" alt="icon"/>
                  </div>
                  <span className="cl-white mg-l-10">Change Avatar</span>
                </div>
            
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="tranf-rotate-180" fill='#FFFFFF'><path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path></svg>
              </Link>

              <Link className="row-view cl-black pd-15 sb-view txt-deco-n mg-t-10 br-10 bg-grey-black" to={"/changename"}>
                <div className="col-view">
                  <span className='cl-white'>{getStorage("uname")}</span>
                  <span className="cl-white ft-sz-12">Change Name</span>
                </div>
            
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="tranf-rotate-180" fill='#FFFFFF'><path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path></svg>
              </Link>

              <Link className="row-view cl-black pd-15 sb-view txt-deco-n mg-t-10 br-10 bg-grey-black" to={"/changepassword"}>
                <div className="col-view">
                  <span className='cl-white'>********</span>
                  <span className="cl-white ft-sz-12">Change Password</span>
                </div>
            
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="tranf-rotate-180" fill='#FFFFFF'><path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path></svg>
              </Link>

           </div>
          </div>
        </div>
      </div>
    );
}

export default UpdateAccount;