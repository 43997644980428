import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import '../../MainStyle.css';
import { getStorage, setStorage } from '../modals/Storage';
import { API_ACCESS_URL } from '../modals/Constants';

const bettingDialog = ({gameCode,dialogData,updateState,toastUpdate,recentBetDetails}) => {

    const [dialogConstants, setDialogData] = useState({
        contractAmnt: 10,
        contracTotalAmnt: 10,
        contractNumLot: 1,
        isLoadingShow: false,
    });

    
    const updateContractData = (data) => {
        updateTotalContractAmnt(dialogConstants.contractNumLot,data);

        setDialogData(previousState => {
            return { ...previousState, contractAmnt: data }
        });
    }

    const updateTotalContractAmnt = (lotSize,contractAmnt) =>{
        let updatedContractAmount = lotSize * contractAmnt;

        setDialogData(previousState => {
            return { ...previousState, contracTotalAmnt: updatedContractAmount }
        });
    }

    const updateLotData = (data) => {
        let updatedData = dialogConstants.contractNumLot + Number(data);

        if(updatedData >= 1 && updatedData < 200){
            updateTotalContractAmnt(updatedData, dialogConstants.contractAmnt);

            setDialogData(previousState => {
                return { ...previousState, contractNumLot: updatedData }
            });
        }
        
    }

    const resetDialogData = (e) => {
        if(e!=null && e.target.className=="ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog"){
            setDialogData(previousState => {
                return { ...previousState, contractAmnt: 10 }
            });
    
            setDialogData(previousState => {
                return { ...previousState, contracTotalAmnt: 10 }
            });
    
            setDialogData(previousState => {
                return { ...previousState, contractNumLot: 1 }
            });

            setDialogData(previousState => {
                return { ...previousState, isLoadingShow: false }
            });
        }
    }

    const confirmBet = (gameCode,dialogData) => {

        setDialogData(previousState => {
            return { ...previousState, isLoadingShow: true }
        });


        async function requestAPI(url,formData) {
  
            try {
              const res = await fetch(url, {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json',
                  'Route': dialogData.gameBetRoutePath,
                  'AuthToken': getStorage("secret"),
                },
                body: JSON.stringify(formData),
              });

              const data = await res.json();

              updateState(null,'dismiss','');
              
              if(data.status_code=="success"){
                setStorage("balance", data.account_balance, 30);
                toastUpdate(true, "Bet Success");
                recentBetDetails(dialogConstants.contracTotalAmnt);
              }else if(data.status_code=="balance_error"){
                toastUpdate(true, "Your balance is insufficient ! Please recharge now !");
              }else if(data.status_code=="account_error"){
                toastUpdate(true, "Account Problem ! Please try again!");
              }else if(data.status_code=="betting_timeout_error"){
                toastUpdate(true, "Oops! Betting timeout! Please try again!");
              }else if(data.status_code=="auth_error"){
                toastUpdate(true, "Authentication Error! Please login again!");
              }else{
                toastUpdate(true, "There was a technical issue! Please try again!");
              }

              setDialogData(previousState => {
                return { ...previousState, isLoadingShow: false }
              });
              
            } catch (error) {
              updateState(null,'dismiss','');
              toastUpdate(true, "There was a technical issue! Please try again!");
            }
        };

        const formData = { USER_ID: getStorage("uid"),BET_ON: dialogData.gameSelectedBet,NUM_LOT: dialogConstants.contractNumLot,CONTRACT_AMOUNT:  dialogConstants.contractAmnt,PROJECT_NAME: gameCode};

        requestAPI(API_ACCESS_URL,formData);
    }

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg ${dialogData.bettingDialogShow==true ? 'activeDialog' : ''}`} onClick={(e)=>{updateState(e,false,'','');resetDialogData(e)}}>
              <div className={`dlg-c ps-fx ps-btm z-i-1000 res-wth bg-black ${dialogData.bettingDialogTheme}`}>
                <p className='ft-sz-23 w-100 pd-10 mg-b-10 dlg-thm-txt txt-a-center br-right-t br-left-t bg-l-white-2'>{dialogData.bettingDialogTitle}</p>

                <div className='pd-15'>

                <div className='row-view sb-view h-50-p w-100 pd-10 br-10 bg-l-white-2'>
                    <p className='cl-white ft-sz-20'>Balance</p>
                    <p className='cl-white ft-sz-23'>₹{getStorage("balance")}</p>
                </div>

                <div className='col-view mg-t-10 w-100'>
                    <p className='cl-white'>Contract Money</p>

                    <div className='row-view mg-t-5'>
                        <div className={`smpl-btn ft-sz-16 ${dialogConstants.contractAmnt=="10" ? 'a-smpl-btn' : ''}`} onClick={()=>updateContractData('10')}>10</div>
                        <div className={`smpl-btn mg-l-5 ft-sz-16 ${dialogConstants.contractAmnt=="100" ? 'a-smpl-btn' : ''}`} onClick={()=>updateContractData('100')}>100</div>
                        <div className={`smpl-btn mg-l-5 ft-sz-16 ${dialogConstants.contractAmnt=="1000" ? 'a-smpl-btn' : ''}`} onClick={()=>updateContractData('1000')}>1000</div>
                        <div className={`smpl-btn mg-l-5 ft-sz-16 ${dialogConstants.contractAmnt=="10000" ? 'a-smpl-btn' : ''}`} onClick={()=>updateContractData('10000')}>10000</div>
                    </div>
                </div>

                <div className='col-view mg-t-15 w-100'>
                    <p className='cl-white'>Number</p>

                    <div className='row-view sb-view mg-t-5'>
                        <div className='row-view'>
                          <div className='smpl-btn ft-sz-16' onClick={()=>updateLotData('-5')}>-5</div>
                          <div className='smpl-btn ft-sz-16 mg-l-5' onClick={()=>updateLotData('-1')}>-1</div>
                        </div>

                        <h3 className='cl-golden ft-sz-25'>{dialogConstants.contractNumLot}</h3>

                        <div className='row-view'>
                          <div className='smpl-btn ft-sz-16' onClick={()=>updateLotData('1')}>+1</div>
                          <div className='smpl-btn ft-sz-16 mg-l-5' onClick={()=>updateLotData('5')}>+5</div>
                        </div>
                        
                    </div>
                </div>

                <p className='mg-t-15 w-100'>Total contract money is {dialogConstants.contracTotalAmnt}</p>
                <div className='row-view w-100'>
                  <div className='w-30 h-50-p ft-sz-20 pd-10 br-10 cl-white v-center mg-t-15 bg-l-white-2' onClick={(e)=>{updateState(e,'dismiss','','');resetDialogData(e)}}>
                    <span>Cancel</span>
                  </div>
                  <div className='w-70 h-50-p ft-sz-20 pd-10 br-10 cl-white v-center mg-t-15 mg-l-10 bg-cyan-blue bx-cyan-blue' onClick={()=>confirmBet(gameCode,dialogData)}>
                    <div className={`h-20-p w-20-p lodr-v ${dialogConstants.isLoadingShow==false ? 'hide-v' : ''}`}></div>
                    <span className={`${dialogConstants.isLoadingShow==true ? 'hide-v' : ''}`}>Continue</span>
                  </div>
                </div>

                </div>
              </div>
        </div>
    );
};

export default bettingDialog;