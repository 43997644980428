import React from 'react'
import { Link,useNavigate  } from 'react-router-dom';
import '../css/navbar.css';

const GameTopBar = ({intentData,multiBtn1,updateState}) => {
  const navigate = useNavigate();
  
    return (
        <nav className="ps-fx ps-tp res-wth pd-10 row-view sb-view z-i-120 bg-grad-grey topbar-shadow">
          
          <div className='row-view a-flex-start'>
           <Link className={`${multiBtn1 =='' ? '' : 'hide-v'}`} onClick={() => navigate(-1)}>
             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FFFFFF"><path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path></svg>
           </Link>

           <div className='col-view mg-l-15'>
             <span className={`cl-white ft-sz-18 ${intentData.pageTitle =='' ? 'hide-v' : ''}`}>{intentData.pageTitle}</span>
             <span className='cl-white ft-sz-14 mg-t-10'>Please enter your mobile number to continue.</span>
           </div>           
           
          </div>
        </nav>
    );
};

export default GameTopBar;