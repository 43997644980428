import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import BottomNavbar from '../other-components/BottomNavbar';
import { getStorage, setStorage } from '../modals/Storage';
import { API_ACCESS_URL } from "../modals/Constants";
import TodayWinners from '../other-components/TodayWinners';

  function Winners() {
  const navigate = useNavigate();
  const [pageConst, setConstants] = useState({
      gameTitle: "Winner",
      isLoadingShow: false,
      recordList: [],
  });

  const updateLoadingStatus = (data) => {
      setConstants(previousState => {
          return { ...previousState, isLoadingShow: data }
      });
  }

  const updateRecordList = (data) => {
    let tempData = [];
    let dataList = data.data;

      for (let i = 0; i < dataList.length; i++) {          
          tempData.push(
            <div key={i} className="row-view sb-view w-100 mg-t-10">
              <div className="h-w-36 br-50 v-center cl-white bg-drk-blue">{dataList[i]['t_msg']=='Level 2' ? 'Lv2' : dataList[i]['t_msg']=='Level 2' ? 'Lv2' : 'Lv1'}</div>
              
              <div className="row-view sb-view w-85">
                <div className="col-view">
                  <span className="ft-sz-17">{dataList[i]['t_msg']!='Recharge Bonus' ? dataList[i]['t_msg']+'- Commission' : dataList[i]['t_msg']}</span>
                  <div className="row-view ft-sz-12 cl-l-grey mg-t-5">
                   <span>{dataList[i]['t_date']+' '+dataList[i]['t_time']}</span>
                   <span className="mg-l-15">from {dataList[i]['t_receive_from']}</span>
                  </div>
                </div>

                <span>+₹{dataList[i]['t_amount']}</span>
              </div>

            </div>)

            if(dataList.length-1 > i){
              tempData.push(
                <div key={i+25} className="line-hv-grey mg-t-10"></div>
               )
            }
            
      };

      setConstants({...pageConst, todaysEarning: data['todays_income'], todaysInvite: data['todays_invite'], totalInvite: data['total_invite'], totalIncome: data['total_income']});
      setConstants(previousState => {
          return { ...previousState, recordList: tempData }
      });
  }


  function getCommissionRecords(){
      const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-commission-records',
            'AuthToken': getStorage("secret"),
          }
        });
        const data = await res.json();
        console.log(data);

          if(data.status_code="success"){
            setStorage("cbalance", data.total_income, 30);
            updateRecordList(data); 
          }
      } catch (error) {
      }
    };

    fecthApiData(API_ACCESS_URL+"?USER_ID="+getStorage("uid")+"&PAGE_NUM=1");
  }

  useEffect(() => {
    if(!getStorage("uid")){
      navigate('/LG', { replace: true });
    }else {
      getCommissionRecords();
    }
  }, []);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh res-wth bg-black ovf-scrl-y hide-sb">

      <div className='zoomAnimView'>
        <TodayWinners />
      </div>

      <BottomNavbar activeBar="winners"/>

      </div>
    </div>
  );
}

export default Winners;